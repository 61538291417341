@import "ClientApp/styles/base/base.scss";
.icon-texts {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  place-content: var(--icontext-m-place-items, center);
  flex-direction: var(--icontext-m-direction, column);
  gap: var(--icontext-m-gap, 1rem);

  .icon-text {
    display: flex;
    place-items: var(--icontext-item-m-place-items, center);
    flex-direction: var(--icontext-item-m-direction, column);
    gap: var(--icontext-item-m-gap, 0.5rem);

    .text {
      color: var(--icontext-text-color, --color-font-dark);
      font-weight: var(--icontext-text-font-weight, 400);
      text-transform: var(--icontext-text-transform, normal);
      font-size: var(--icontext-m-text-size, 16px);
    }
  }

  @media (min-width: $screen-small) {
    flex-direction: var(--icontext-d-direction, row);
    place-content: var(--icontext-d-place-items, center);
    gap: var(--icontext-d-gap, 1rem);

    .icon-text {
      place-items: var(--icontext-item-d-place-items, center);
      flex-direction: var(--icontext-item-d-direction, row);
      gap: var(--icontext-item-d-gap, 0.5rem);

      .text {
        width: 100%;
        font-size: var(--icontext-d-text-size, 16px);
      }
    }
  }
}

.admin-mode {
  .dropzone-image {
    min-width: 150px;
  }
}
